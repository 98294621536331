.App {
}

.App-logo {
  height: 80px;
  padding-top: 10px;
  padding-bottom: 45px;
  margin-left: 10px;
  pointer-events: none;
  float: left;
  position: relative;
  left: 8%;
  overflow: hidden;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.MuiOutlinedInput-notchedOutline {
  border-color: rgba(255, 255, 255, 0.5) !important;
  border-width: 2px !important;
  border-radius: 32px !important;
}

.MuiInputAdornment-positionEnd {
  background-color: rgba(255, 255, 255, 0.0) !important;
}


.MuiOutlinedInput-input {
  color: rgba(255, 255, 255, 0.5) !important;
  padding-left: 24px !important;
  width: 120px !important;
}

.MuiPickersPopper-paper {
  background-color: rgba(255, 255, 255, 0.0) !important;
}

.MuiDayCalendar-root {
  background-color: rgb(211, 237, 255) !important;
}



.MuiInputAdornment-positionEnd {
  padding-right: 8px !important;
}

.MuiPickersDay-root {
  background-color: rgba(255, 255, 255, 0.0) !important;
}

.Mui-selected {
  border-width: 0px !important;
  background-color: rgba(3, 147, 252, 0.9) !important;
}

.MuiPickersDay-today {
  border-width: 2px !important;
  border-color: rgba(3, 147, 252, 0.9) !important;
}
.drawerInfo a {
  color: inherit;
  text-decoration: inherit;
}

.MuiFab-root {
  background-color: rgba(3, 147, 252, 0.9) !important
}

.MuiSvgIcon-root {
  color: rgba(255, 255, 255, 0.5) !important;
}

.App-header {
  position: absolute;
  top: 0;
  z-index: 101;
  background-image: linear-gradient(
    to right,
    rgba(255, 255, 255, 0.0),
    rgba(3, 147, 252, 0.9),
    rgba(3, 147, 252, 0.3)
  );
  width: 100%;
  float: left;
  align-items: left;
  justify-content: left;
  font-size: calc(10px + 2vmin);
  color: white;
  padding-left: 10%;
  padding-right: 10%;
  clip-path: polygon(
    100% 0%,
    0% 0%,
    0% 70.96%,
    2% 71.73%,
    4% 72.29%,
    6% 72.62%,
    8% 72.73%,
    10% 72.61%,
    12% 72.26%,
    14% 71.69%,
    16% 70.9%,
    18% 69.9%,
    20% 68.69%,
    22% 67.29%,
    24% 65.7%,
    26% 63.94%,
    28% 62.01%,
    30% 59.95%,
    32% 57.76%,
    34% 55.45%,
    36% 53.05%,
    38% 50.58%,
    40% 48.05%,
    42% 45.49%,
    44% 42.91%,
    46% 40.33%,
    48% 37.77%,
    50% 35.26%,
    52% 32.81%,
    54% 30.44%,
    56% 28.18%,
    58% 26.03%,
    60% 24.01%,
    62% 22.14%,
    64% 20.44%,
    66% 18.92%,
    68% 17.58%,
    70% 16.44%,
    72% 15.51%,
    74% 14.8%,
    76% 14.31%,
    78% 14.04%,
    80% 14%,
    82% 14.18%,
    84% 14.59%,
    86% 15.23%,
    88% 16.08%,
    90% 17.14%,
    92% 18.41%,
    94% 19.86%,
    96% 21.5%,
    98% 23.31%,
    100% 25.27%
  );
}
.App-link {
  color: #61dafb;
}

@keyframes fadeAnimation {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.fade {
  background-color: black;
  animation: fadeAnimation 3s ease-in;
  pointer-events: none;
  overflow: hidden;
  animation-fill-mode: forwards;
}

.solid {
  background-color: black;
  pointer-events: none;
  overflow: hidden;
}

.date-picker {
  position: absolute;
  bottom: 16px;
  right: 90px;
  z-index: 101;
}
