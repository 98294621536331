@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.popup::-webkit-scrollbar {
  display: none;
}
.popup {
  position: absolute;
  /* background-color: white; */
  padding: 10px;
  padding-top: 100px;
  /* border: 1px solid #ccc; */
  z-index: 9999;
  left: 8%;
  top: 100px;
  /* Add any other styles as needed */
  animation-name: fadein;
  animation-duration: 0.2s;
  overflow-y: auto;
  height: 75vh;
  mask-image: linear-gradient(
    to bottom,
    transparent 0%,
    black 48px,
    black calc(75vh),
    transparent 100%
  );
}

.popup.articles a {
  color: inherit;
  text-decoration: inherit;
}

.popup.info a {
  color: inherit;
  text-underline-offset: .15rem;
  text-decoration-thickness: .2rem;
  text-decoration-skip-ink: none;
  text-decoration-color: rgb(173, 211, 255);
}

.MuiTypography-body-sm {
  color: white;
}

.MuiCard-root {
  background-image: linear-gradient(
    274deg,
    rgb(52, 169, 253),
    rgb(0, 149, 255)
  );
}

/* Target screens that are narrower than 767 pixels */
@media only screen and (max-width: 767px) {
  /* Your CSS rules for smaller screens (which includes most phones) go here */
  .popup {
    padding: 0;
    mask-image: none;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
  }
}
